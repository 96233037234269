import type { RouteConfig } from 'vue-router';
// Vuex modules
// import USER from '@/store/modules/UserModule'; // USER is a direct reference to the Vuex user store
// import MISC_DATA from '@/store/modules/MiscDataModule';

import { routesNames, getRouteAliases, getBasePath } from './route_helper';
import { IDictionary } from '~/types';

export const checkoutStepRedirector: IDictionary<routesNames> = {
	customer_info: routesNames.summary,
	checkout: routesNames.summary,
	switch_app: routesNames.app
};

// Those stupid components import have to end with .then(m => m.default || m)
// Explanation :
// https://stackoverflow.com/questions/66440305/component-dynamic-import-doesnt-work-from-router-js

// Booking route wrapper
const Booking = {
	name: routesNames.booking,
	template: '<div> <router-view :key="$route.path"></router-view> </div>',
	style: 'background-color: red'
};
const Checkout = {
	name: routesNames.checkout,
	template: '<div> <router-view :key="$route.path"></router-view> </div>'
};
const ExtraPayment = {
	name: routesNames.extra_payment_wrapper,
	template: '<div> <router-view :key="$route.path"></router-view> </div>'
};
const ResetPassword = {
	name: routesNames.reset_password,
	template: '<div> <router-view :key="$route.path"></router-view> </div>'
};
const TruckWrapper = {
	name: routesNames.truck_wrapper,
	template: '<div> <router-view :key="$route.path"></router-view> </div>'
};
const AGBWrapper = {
	name: routesNames.agb,
	template:
		'<div> <h1 class="text-center mt-12 spartan-font"> Loading... </h1> <router-view :key="$route.path"></router-view> </div>'
};
const AGBAboWrapper = {
	name: routesNames.agb,
	template:
		'<div> <h1 class="text-center mt-12 spartan-font"> Loading... </h1> <router-view :key="$route.path"></router-view> </div>'
};
const AGBB2bWrapper = {
	name: routesNames.agb,
	template:
		'<div> <h1 class="text-center mt-12 spartan-font"> Loading... </h1> <router-view :key="$route.path"></router-view> </div>'
};

export const Routes: RouteConfig[] = [
	{
		name: routesNames.login,
		path: getBasePath(routesNames.login),
		alias: getRouteAliases(routesNames.login),
		component: () =>
			import(/* webpackChunkName: "login" */ '@/views/Login.vue').then((m) => m.default || m)
	},
	{
		name: routesNames.signup,
		path: getBasePath(routesNames.signup),
		alias: getRouteAliases(routesNames.signup),
		component: () =>
			import(/* webpackChunkName: "Signup" */ '@/views/Signup.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.profile,
		path: getBasePath(routesNames.profile),
		alias: getRouteAliases(routesNames.profile),
		component: () =>
			import(/* webpackChunkName: "profile" */ '@/views/profile/ProfileWrapper.vue').then(
				(m) => m.default || m
			),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				name: routesNames.bookings,
				path: getBasePath(routesNames.bookings),
				alias: getRouteAliases(routesNames.bookings),
				component: () =>
					import(
						/* webpackChunkName: "BookingList" */ '@/views/profile/bookings/BookingList.vue'
					).then((m) => m.default || m),
				children: [
					{
						name: routesNames.booking_details,
						path: getBasePath(routesNames.booking_details),
						component: () =>
							import(
								/* webpackChunkName: "BookingDetails" */ '@/views/profile/bookings/BookingDetails.vue'
							).then((m) => m.default || m),
						children: [
							{
								name: routesNames.upgrade_booking,
								path: getBasePath(routesNames.upgrade_booking),
								alias: getRouteAliases(routesNames.upgrade_booking),
								component: () =>
									import(
										/* webpackChunkName: "UpgradeBooking" */ '@/views/profile/bookings/UpgradeBooking.vue'
									).then((m) => m.default || m),
								children: [
									{
										name: routesNames.booking_upgraded,
										path: getBasePath(routesNames.booking_upgraded),
										component: () =>
											import(
												/* webpackChunkName: "BookingUpgraded" */ '@/views/profile/bookings/BookingUpgraded.vue'
											).then((m) => m.default || m),
										children: [
											{
												name: routesNames.booking_upgraded_summary,
												path: getBasePath(
													routesNames.booking_upgraded_summary
												),
												alias: getRouteAliases(
													routesNames.booking_upgraded_summary
												),
												component: () =>
													import(
														/* webpackChunkName: "BookingUpgradedSummary" */ '@/views/profile/bookings/BookingUpgradedSummary.vue'
													).then((m) => m.default || m)
											},
											{
												name: routesNames.booking_upgraded_thanks,
												path: getBasePath(
													routesNames.booking_upgraded_thanks
												),
												alias: getRouteAliases(
													routesNames.booking_upgraded_thanks
												),
												component: () =>
													import(
														/* webpackChunkName: "BookingUpgradedThanks" */ '@/views/profile/bookings/BookingUpgradedThanks.vue'
													).then((m) => m.default || m)
											}
										]
									}
								]
							},
							{
								name: routesNames.fuel_receipt,
								path: getBasePath(routesNames.fuel_receipt),
								alias: getRouteAliases(routesNames.fuel_receipt),
								component: () =>
									import(
										/* webpackChunkName: "UploadFuelReceipt" */ '@/views/profile/bookings/UploadFuelReceipt.vue'
									).then((m) => m.default || m)
							}
						]
					}
				]
			},
			{
				name: routesNames.app_login_link,
				path: getBasePath(routesNames.app_login_link),
				alias: getRouteAliases(routesNames.app_login_link),
				component: () =>
					import(
						/* webpackChunkName: "AppLoginLink" */ '~/views/profile/AppLoginLink.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.invoices,
				path: getBasePath(routesNames.invoices),
				alias: getRouteAliases(routesNames.invoices),
				component: () =>
					import(
						/* webpackChunkName: "InvoiceList" */ '@/views/profile/InvoiceList.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.abo,
				path: getBasePath(routesNames.abo),
				component: () =>
					import(/* webpackChunkName: "ProAbo" */ '@/views/profile/ProAbo.vue').then(
						(m) => m.default || m
					)
			},
			{
				name: routesNames.cancel_abo,
				path: getBasePath(routesNames.cancel_abo),
				alias: getRouteAliases(routesNames.cancel_abo),
				component: () =>
					import(
						/* webpackChunkName: "CancelAbo" */ '@/views/profile/CancelAbo.vue'
					).then((m) => m.default || m)
			}
		]
	},

	{
		name: routesNames.reset_password_wrapper,
		path: getBasePath(routesNames.reset_password_wrapper),
		alias: getRouteAliases(routesNames.reset_password_wrapper),
		component: ResetPassword,
		children: [
			{
				name: routesNames.reset_password,
				path: getBasePath(routesNames.reset_password),
				component: () =>
					import(
						/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue'
					).then((m) => m.default || m)
			}
		]
	},

	// Booking routes
	{
		name: routesNames.booking,
		path: getBasePath(routesNames.booking),
		alias: getRouteAliases(routesNames.booking),
		component: Booking,
		children: [
			{
				name: routesNames.upsells_1,
				path: getBasePath(routesNames.upsells_1),
				alias: getRouteAliases(routesNames.upsells_1),
				component: () =>
					import(
						/* webpackChunkName: "UpsellsInsurance" */ '@/views/booking/UpsellsInsurance.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.upsells_2,
				path: getBasePath(routesNames.upsells_2),
				alias: [...getRouteAliases(routesNames.upsells_2), ...['upsells2']],
				component: () =>
					import(
						/* webpackChunkName: "UpsellsExtras" */ '@/views/booking/UpsellsExtras2.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.info,
				meta: { requiresAuth: true },
				path: getBasePath(routesNames.info),
				alias: getRouteAliases(routesNames.info),
				component: () =>
					import(
						/* webpackChunkName: "CustomerInfo" */ '@/views/booking/CustomerInfo.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.checkout,
				path: getBasePath(routesNames.checkout),
				component: Checkout,
				children: [
					{
						name: routesNames.summary,
						path: getBasePath(routesNames.summary),
						alias: getRouteAliases(routesNames.summary),
						component: () =>
							import(
								/* webpackChunkName: "Summary" */ '@/views/booking/Summary2.vue'
							).then((m) => m.default || m)
					},
					{
						name: routesNames.app,
						path: getBasePath(routesNames.app),
						alias: getRouteAliases(routesNames.app),
						meta: { requiresAuth: true },
						component: () =>
							import(
								/* webpackChunkName: "ProposeApp" */ '@/views/booking/ProposeApp.vue'
							).then((m) => m.default || m)
					}
				]
			}
		]
	},
	// End of Booking routes

	{
		name: routesNames.extra_payment_wrapper,
		path: getBasePath(routesNames.extra_payment_wrapper),
		alias: getRouteAliases(routesNames.extra_payment_wrapper),
		component: ExtraPayment,
		meta: { requiresAuth: true },
		children: [
			{
				name: routesNames.extra_payment,
				path: getBasePath(routesNames.extra_payment),
				component: () =>
					import(
						/* webpackChunkName: "ExtraPayment" */ '@/views/booking/ExtraPayment.vue'
					).then((m) => m.default || m),
				children: [
					{
						name: routesNames.extra_payment_thanks,
						path: getBasePath(routesNames.extra_payment_thanks),
						alias: getRouteAliases(routesNames.extra_payment_thanks),
						component: () =>
							import(
								/* webpackChunkName: "ExtraPayment" */ '@/views/booking/ExtraPayment.vue'
							).then((m) => m.default || m)
					}
				]
			}
		]
	},

	{
		name: routesNames.about,
		path: getBasePath(routesNames.about),
		alias: getRouteAliases(routesNames.about),
		component: () =>
			import(/* webpackChunkName: "About" */ '@/views/About.vue').then((m) => m.default || m)
	},
	{
		name: routesNames.imprint,
		path: getBasePath(routesNames.imprint),
		alias: getRouteAliases(routesNames.imprint),
		component: () =>
			import(/* webpackChunkName: "Imprint" */ '@/views/Imprint.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.contact,
		path: getBasePath(routesNames.contact),
		alias: getRouteAliases(routesNames.contact),
		component: () =>
			import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.faq,
		path: getBasePath(routesNames.faq),
		alias: getRouteAliases(routesNames.faq),
		component: () =>
			import(/* webpackChunkName: "FAQ" */ '@/views/FAQ.vue').then((m) => m.default || m)
	},
	{
		name: routesNames.usage_rules,
		path: getBasePath(routesNames.usage_rules),
		alias: getRouteAliases(routesNames.usage_rules),
		component: () =>
			import(/* webpackChunkName: "UsageRules" */ '@/views/UsageRules.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.sustainability,
		path: getBasePath(routesNames.sustainability),
		alias: getRouteAliases(routesNames.sustainability),
		component: () =>
			import(/* webpackChunkName: "Sustainability" */ '@/views/Sustainability.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.payment_methods,
		path: getBasePath(routesNames.payment_methods),
		alias: getRouteAliases(routesNames.payment_methods),
		component: () =>
			import(/* webpackChunkName: "PaymentMethods" */ '@/views/PaymentMethods.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.jobs,
		path: getBasePath(routesNames.jobs),
		alias: getRouteAliases(routesNames.jobs),
		component: () =>
			import(/* webpackChunkName: "Jobs" */ '@/views/Jobs.vue').then((m) => m.default || m)
	},
	{
		name: routesNames.fleet_partner,
		path: getBasePath(routesNames.fleet_partner),
		alias: getRouteAliases(routesNames.fleet_partner),
		component: () =>
			import(/* webpackChunkName: "Franchise" */ '@/views/Franchise.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.location_partner,
		path: getBasePath(routesNames.location_partner),
		alias: getRouteAliases(routesNames.location_partner),
		component: () =>
			import(/* webpackChunkName: "LocationPartner" */ '@/views/LocationPartner.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.long_term_rent,
		path: getBasePath(routesNames.long_term_rent),
		alias: getRouteAliases(routesNames.long_term_rent),
		component: () =>
			import(/* webpackChunkName: "LongTermRent" */ '@/views/LongTermRent.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.all_trucks,
		path: getBasePath(routesNames.all_trucks),
		alias: getRouteAliases(routesNames.all_trucks),
		component: () =>
			import(/* webpackChunkName: "AllTrucks" */ '@/views/AllTrucks.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.truck_wrapper,
		path: getBasePath(routesNames.truck_wrapper),
		alias: getRouteAliases(routesNames.truck_wrapper),
		component: TruckWrapper,
		children: [
			{
				name: routesNames.truck,
				path: getBasePath(routesNames.truck),
				component: () =>
					import(/* webpackChunkName: "PressList" */ '@/views/Truck.vue').then(
						(m) => m.default || m
					)
			}
		]
	},
	{
		name: routesNames.press,
		path: getBasePath(routesNames.press),
		alias: getRouteAliases(routesNames.press),
		component: () =>
			import(/* webpackChunkName: "PressWrapper" */ '@/views/press/PressWrapper.vue').then(
				(m) => m.default || m
			),
		children: [
			{
				name: routesNames.press_list,
				path: getBasePath(routesNames.press_list),
				alias: getRouteAliases(routesNames.press_list),
				component: () =>
					import(
						/* webpackChunkName: "PressList" */ '@/components/press/PressList.vue'
					).then((m) => m.default || m)
			},
			{
				name: routesNames.us_in_media,
				path: getBasePath(routesNames.us_in_media),
				alias: getRouteAliases(routesNames.us_in_media),
				component: () =>
					import(/* webpackChunkName: "UsInMedia" */ '@/views/press/UsInMedia.vue').then(
						(m) => m.default || m
					)
			},
			{
				name: routesNames.press_epk,
				path: getBasePath(routesNames.press_epk),
				component: () =>
					import(/* webpackChunkName: "PressEPK" */ '@/views/press/PressEPK.vue').then(
						(m) => m.default || m
					)
			},
			{
				name: routesNames.press_article,
				path: '*',
				component: () =>
					import(
						/* webpackChunkName: "PressArticle" */ '@/components/press/PressArticle.vue'
					).then((m) => m.default || m),
				props: true
			}
		]
	},
	{
		name: routesNames.our_app,
		path: getBasePath(routesNames.our_app),
		alias: getRouteAliases(routesNames.our_app),
		component: () =>
			import(/* webpackChunkName: "OurApp" */ '@/views/AppInfo.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.damage_info,
		path: getBasePath(routesNames.damage_info),
		alias: getRouteAliases(routesNames.damage_info),
		component: () =>
			import(/* webpackChunkName: "OurApp" */ '@/views/DamageInfo.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.cust_experience,
		path: getBasePath(routesNames.cust_experience),
		alias: getRouteAliases(routesNames.cust_experience),
		component: () =>
			import(
				/* webpackChunkName: "CustomerExperience" */ '@/views/CustomerExperience.vue'
			).then((m) => m.default || m)
	},
	{
		name: routesNames.home,
		path: getBasePath(routesNames.home),
		component: () =>
			import(/* webpackChunkName: "Home" */ '@/views/Home.vue').then((m) => m.default || m),
		children: [
			{
				name: routesNames.partner_obi,
				path: getBasePath(routesNames.partner_obi),
				alias: getRouteAliases(routesNames.partner_obi)
			},
			{
				name: routesNames.partner_bellaflora,
				path: getBasePath(routesNames.partner_bellaflora),
				alias: getRouteAliases(routesNames.partner_bellaflora)
			},
			{
				name: routesNames.partner_dehner,
				path: getBasePath(routesNames.partner_dehner),
				alias: getRouteAliases(routesNames.partner_dehner)
			},
			{
				name: routesNames.partner_bauwelt,
				path: getBasePath(routesNames.partner_bauwelt),
				alias: getRouteAliases(routesNames.partner_bauwelt)
			},
			{
				name: routesNames.partner_sconto,
				path: getBasePath(routesNames.partner_sconto),
				alias: getRouteAliases(routesNames.partner_sconto)
			},
			{
				name: routesNames.partner_hornbach,
				path: getBasePath(routesNames.partner_hornbach),
				alias: getRouteAliases(routesNames.partner_hornbach)
			},
			{
				name: routesNames.partner_olympia,
				path: getBasePath(routesNames.partner_olympia),
				alias: getRouteAliases(routesNames.partner_olympia)
			},
			{
				name: routesNames.partner_teubl,
				path: getBasePath(routesNames.partner_teubl),
				alias: getRouteAliases(routesNames.partner_teubl)
			},
			{
				name: routesNames.partner_raiffeisen_lagerhaus,
				path: getBasePath(routesNames.partner_raiffeisen_lagerhaus),
				alias: getRouteAliases(routesNames.partner_raiffeisen_lagerhaus)
			},
			{
				name: routesNames.partner_momax,
				path: getBasePath(routesNames.partner_momax),
				alias: getRouteAliases(routesNames.partner_momax)
			},
			{
				name: routesNames.partner_bauhaus,
				path: getBasePath(routesNames.partner_bauhaus),
				alias: getRouteAliases(routesNames.partner_bauhaus)
			},
			{
				name: routesNames.partner_uni_hobby,
				path: getBasePath(routesNames.partner_uni_hobby),
				alias: getRouteAliases(routesNames.partner_uni_hobby)
			},
			{
				name: routesNames.partner_tesco,
				path: getBasePath(routesNames.partner_tesco),
				alias: getRouteAliases(routesNames.partner_tesco)
			},
			{
				name: routesNames.landing_page,
				path: getBasePath(routesNames.landing_page),
				alias: getRouteAliases(routesNames.landing_page),
				component: () =>
					import(/* webpackChunkName: "LandingPage" */ '@/views/LandingPage.vue').then(
						(m) => m.default || m
					),
				props: true
			}
		]
	},
	{
		name: routesNames.privacy_policy,
		path: getBasePath(routesNames.privacy_policy),
		alias: getRouteAliases(routesNames.privacy_policy),
		component: () =>
			import(/* webpackChunkName: "Datenschutz" */ '@/views/PrivacyPolicy.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.privacy_policy_phone,
		path: getBasePath(routesNames.privacy_policy_phone),
		alias: getRouteAliases(routesNames.privacy_policy_phone),
		component: () =>
			import(/* webpackChunkName: "Datenschutz" */ '@/views/PrivacyPolicyPhone.vue').then(
				(m) => m.default || m
			)
	},
	{
		name: routesNames.agb,
		path: getBasePath(routesNames.agb),
		alias: getRouteAliases(routesNames.agb),
		component: AGBWrapper
	},
	{
		name: routesNames.agb_abo,
		path: getBasePath(routesNames.agb_abo),
		alias: getRouteAliases(routesNames.agb_abo),
		component: AGBAboWrapper
	},
	{
		name: routesNames.agb_b2b,
		path: getBasePath(routesNames.agb_b2b),
		alias: getRouteAliases(routesNames.agb_b2b),
		component: AGBB2bWrapper
	},
	{
		name: routesNames.page_not_found, // needs to be last
		path: getBasePath(routesNames.page_not_found),
		alias: ['*', '*/*'],
		component: () =>
			import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue').then(
				(m) => m.default || m
			)
	}
];
